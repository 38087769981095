import React, { useEffect, useContext, useState } from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import Layout from "../components/Layout";
import { useEmailConfirmation, useSendEmailConfirmation } from "../mutations";
import queryString from "query-string";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import { CustomerContext, UserAuthContext } from "../context";
import { useTrans } from "../hooks";

const EmailConfirmation = ({ pageContext, location }) => {
  const { seoData, layoutProps } = pageContext;

  const transMsg = useTrans();
  const [data, loading, error, setVariable] = useEmailConfirmation();
  const [email, setEmail] = useState("");
  const { error: sendEmalError, setData: setSendEmalVariable } =
    useSendEmailConfirmation();
  const { isAuthenticated, setUser } = useContext(UserAuthContext);
  const { customer } = useContext(CustomerContext);

  useEffect(() => {
    if (_.isEmpty(location.search)) return;
    const params = queryString.parse(location.search);
    const { confirmation } = params;
    if (_.isEmpty(confirmation)) return;

    setVariable({ variables: { confirmation: confirmation } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (_.isEmpty(data)) return;
    const accessToken = data.emailConfirmation.jwt;
    if (_.isEmpty(accessToken)) return;

    setUser({
      email: data.emailConfirmation.user.email,
      accessToken: accessToken,
      id: data.emailConfirmation.user.id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSendEmailConfirmation = (e) => {
    e.preventDefault();
    setSendEmalVariable({
      variables: {
        email: email,
        restaurant: process.env.GATSBY_STRAPI_RESTAURANT_ID,
      },
    });
  };
  return (
    <Layout seo={seoData} {...layoutProps}>
      <Container>
        <Row>
          <Col md={12} className="text-center pt-5 pb-5">
            {_.isEmpty(data) &&
              _.isEmpty(error) &&
              !customer.confirmed &&
              loading && (
                <>
                  <h3> {transMsg("processingYourRequest")}</h3>
                  <Spinner animation="border" color="primary" />
                </>
              )}

            {(data || customer.confirmed) && (
              <>
                <h3 className="mt-2 mb-2">
                  {transMsg("ThanksEmailConfirmed")}
                </h3>

                <Link className="btn btn-primary btn-lg" to="/">
                  {transMsg("goHome")}
                </Link>
              </>
            )}

            {!isAuthenticated && (
              <>
                <div>{JSON.stringify(sendEmalError)}</div>
                <h3 className="mt-2 mb-2 py-4">
                  {transMsg("cannotConfirmYourEmail")}
                </h3>

                <Form onSubmit={onSendEmailConfirmation}>
                  <div className="form-label-group">
                    <Form.Control
                      type="email"
                      id="inputEmail"
                      placeholder={transMsg("emailAddress")}
                      name="email"
                      required
                      onChange={({ target: { value } }) => {
                        setEmail(value);
                      }}
                    />
                    <Form.Label htmlFor="inputEmail">
                      {" "}
                      {transMsg("emailAddress")}
                    </Form.Label>
                  </div>

                  <Button className="btn btn-primary btn-lg" type="submit">
                    {transMsg("sendAgain")}
                  </Button>
                </Form>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default EmailConfirmation;
